import React from "react"

import HeaderAboutUs from "../../components/header_aboutus"

export default function WhyChooseUs() {
  const heroTitle = "Why Choose Us"
  return (
    <HeaderAboutUs heroTitle={heroTitle}>
      <h2 class="h2 h2-sm">{heroTitle}</h2>
      <p>
        For <b>SIMPLICITY</b>. And:
      </p>

      <ul class="list-icon list-icon-check list-icon-colored mt-3">
        <li>
          acting responsibly in your best interest by offering high quality and conflicts-free
          advice
        </li>
        <li>demonstrating alignment of interest through co-investment, to the extent feasible</li>
        <li>disciplined and value-oriented approach to long term investment selection</li>
        <li>focus on risk management and capital preservation</li>
        <li>sensible, transparent and flexible fee philosophy</li>
        <li>
          clearly defined and unwavering dedication to client service to allow you as client to
          focus on your career/business
        </li>
        {/* <li>association with iFast Global Markets</li> */}
      </ul>
    </HeaderAboutUs>
  )
}
